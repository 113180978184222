<template>
  <b-modal
    id="update-requirement-modal"
    title="Update Requirement"
    no-close-on-esc
    no-close-on-backdrop
    @show="onShow"
  >
    <!-- Section & Priority -->
    <div class="w-100 d-inline-flex justify-content-between mb-1">
      <div class="w-100">
        <LabelWithHoverIcon
          label-string="Priority"
          :popover-text="`Requirement priority level (Default: ${defaultPriority})`"
        />

        <b-form-select
          v-if="localReqObj"
          v-model="localReqObj.priority"
          :disabled="loading"
          :options="priorityOptions"
        />
      </div>
      <div class="mx-2" />
      <div class="w-100">
        <LabelWithHoverIcon
          label-string="Security Classification"
          :popover-text="`Australian Government PSF Security classification. (Default: ${defaultSecurityClassification})`"
        />
        <b-form-select
          v-if="localReqObj"
          v-model="localReqObj.classification"
          :disabled="loading"
          :options="securityClassificationOptions"
        />
      </div>
    </div>

    <!-- Object Text -->
    <div v-if="localReqObj" class="d-flex flex-column w-100 mb-1">
      <div class="d-inline-flex justify-content-between align-items-center mb-50">
        <LabelWithHoverIcon
          class="flex-grow-1"
          label-string="Requirement Definition"
          popover-text="The Requirement itself, 'Use Case' format is preferred. Enable the 'Expand' option for requirements with a lot of content."
        />
        <div class="d-inline-flex align-items-center">
          <b-form-checkbox
            :checked="expandRequirementDescription"
            switch
            class="custom-control-info"
            @change="expandRequirementDescription = !expandRequirementDescription"
          >
            <span class="switch-icon-left">
              <feather-icon
                icon="Minimize2Icon"
                class="cursor-pointer"
              />
            </span>
            <span class="switch-icon-right">
              <feather-icon
                icon="Maximize2Icon"
                class="cursor-pointer"
              />
            </span>
          </b-form-checkbox>
          <span class="font-small-3">
            {{ expandRequirementDescription ? 'Collapse' : 'Expand' }}
          </span>
        </div>
      </div>
      <tip-tap-editor
        v-model="localReqObj.object_text"
        placeholder="Compose a requirement..."
        :disabled="loading"
        min-height="20"
        :max-height="expandRequirementDescription ? '' : '20'"
      />
    </div>

    <!-- Additional attributes -->
    <div class="d-flex flex-column w-100">
      <!-- Label Row -->
      <div class="d-inline-flex justify-content-between align-items-center mb-50">
        <LabelWithHoverIcon
          class="flex-grow-1"
          label-string="Additional attributes"
          popover-text="Extra properties to add to a requirement"
        />
        <div class="d-inline-flex align-items-center">
          <b-form-checkbox
            :checked="showAdditionalAttributes"
            switch
            class="custom-control-info"
            @change="showAdditionalAttributes = !showAdditionalAttributes"
          >
            <span class="switch-icon-left">
              <feather-icon
                icon="Minimize2Icon"
                class="cursor-pointer"
              />
            </span>
            <span class="switch-icon-right">
              <feather-icon
                icon="Maximize2Icon"
                class="cursor-pointer"
              />
            </span>
          </b-form-checkbox>
          <span class="font-small-3">
            Attributes {{ showAdditionalAttributes ? 'shown' : 'hidden' }}
          </span>
        </div>
      </div>

      <!-- Existing Attribute Rows -->
      <div v-if="localReqObj && showAdditionalAttributes">
        <div v-for="(value, key) in localReqObj.additional_attributes" :key="key" class="flex-column w-100 px-50 mb-1">
          <LabelWithHoverIcon :label-string="key | capitalizeUnderscoredEachWord" class="mb-25" />
          <div class="d-inline-flex w-100">
            <b-form-input v-model="localReqObj.additional_attributes[key]" placeholder="(empty)" :disabled="loading" />
            <b-button
              class="ml-50"
              variant="flat-danger"
              :disabled="loading"
              @click="removeAttribute(key)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </div>

        <div class="flex-column w-100 px-50 mt-2">
          <LabelWithHoverIcon label-string="New Attribute" class="mb-25" label-class="text-success" />
          <div class="d-inline-flex w-100">
            <b-form-input v-model="newAttributeKey" :disabled="loading" placeholder="Attribute Name" />
            <b-button
              size="sm"
              class="mx-50 text-nowrap"
              variant="flat-success"
              :disabled="loading || newAttributeKey.length === 0"
              @click="addAttribute"
            >
              <feather-icon icon="PlusIcon" />
              Add New
            </b-button>
          </div>
        </div>

        <p class="font-small-3 px-50 mt-1 mb-0 text-muted">
          <span class="font-weight-bolder">Note:</span>
          Empty attribute values are not saved to the Requirement. Attributes shown are derived from additional
          attributes on this Requirement and additional attributes used on other Requirements in the same Specification.
        </p>
      </div>
    </div>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" :disabled="loading" @click="cancel">
        Discard
      </b-button>
      <b-button variant="success" :disabled="loading" @click="onSubmit">
        <b-spinner v-if="loading" small class="mr-50" />
        {{ loading ? 'Updating' : 'Update' }} Requirement
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import LabelWithHoverIcon from '@/components/Forms/LabelWithHoverIcon.vue'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import Vue from 'vue'

export default {
  name: 'UpdateRequirementModal',
  components: {
    LabelWithHoverIcon,
    TipTapEditor,
  },
  props: {
    selectedRequirement: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const loading = ref(false)
    const localReqObj = ref({})
    const etag = ref(null)

    const loadRequirementDetails = () => {
      loading.value = true
      localReqObj.value = null
      store
        .dispatch('requirement/getRequirementDetails', props.selectedRequirement.id)
        .then(response => {
          localReqObj.value = response.data
          etag.value = response.headers.etag

          // Ensure that all additional_attribute values are a String
          Object.entries(localReqObj.value.additional_attributes).forEach(([key, value]) => {
            if (typeof value !== 'string' && value !== 'number') {
              localReqObj.value.additional_attributes[key] = String(value)
            }
          })

          fetchAdditionalSpecAttributes()
        })
        .finally(() => { loading.value = false })
    }

    // Get list of columns for the additional attributes section
    const fetchAdditionalSpecAttributes = () => {
      store
        .dispatch('requirementsTable/getAdditionalCustomColumns')
        .then(columns => {
          const additionalSpecAttributes = columns.reduce((obj, item) => {
            obj[item.toLowerCase()] = ''
            return obj
          }, {})
          const additionalReqAttributes = localReqObj.value.additional_attributes
          localReqObj.value.additional_attributes = { ...additionalSpecAttributes, ...additionalReqAttributes }
        })
    }

    const onShow = () => { loadRequirementDetails() }
    const onSubmit = () => {
      loading.value = true
      store
        .dispatch('requirement/updateRequirementDetails', {
          id: localReqObj.value.id,
          etag: etag.value,
          data: {
            priority: localReqObj.value.priority,
            classification: localReqObj.value.classification,
            object_text: localReqObj.value.object_text,
            status: localReqObj.value.status,
            spec_id: localReqObj.value.spec_id,
            additional_attributes: localReqObj.value.additional_attributes,
          },
        })
        .then(response => {
          context.emit('on-submit')
          store.commit('requirementsTable/INJECT_UPDATED_REQUIREMENT', response.data)
        })
        .finally(() => {
          loading.value = false
          context.root.$bvModal.hide('update-requirement-modal')
        })
    }

    // Form properties
    const defaultPriority = store.state.constants.defaultRequirementPriority.id
    const defaultSecurityClassification = store.state.constants.defaultSecurityClassification.id
    const priorityOptions = computed(() => store.getters['constants/requirementPriorities'])
    const securityClassificationOptions = computed(() => store.getters['constants/securityClassifications'])
    const expandRequirementDescription = ref(false)
    const showAdditionalAttributes = ref(true)

    // Additional Attributes
    const newAttributeKey = ref('')
    const addAttribute = () => {
      newAttributeKey.value = newAttributeKey.value.replace(/ /g, '_')
      newAttributeKey.value = newAttributeKey.value.toLowerCase()
      newAttributeKey.value = newAttributeKey.value.trim()
      Vue.set(localReqObj.value.additional_attributes, newAttributeKey.value, '')
      newAttributeKey.value = ''
    }
    const removeAttribute = key => { localReqObj.value.additional_attributes[key] = '' }

    return {
      onShow,
      onSubmit,

      loading,
      defaultPriority,
      defaultSecurityClassification,
      priorityOptions,
      securityClassificationOptions,
      expandRequirementDescription,
      showAdditionalAttributes,
      localReqObj,
      newAttributeKey,
      addAttribute,
      removeAttribute,
    }
  },
}
</script>

<style lang="scss">
// Set the modal width manually because the default Bootstrap ones aren't sufficient
#update-requirement-modal .modal-dialog {
  max-width: 80vw;
}
</style>
