<template>
  <vue-context ref="menu">
    <li v-if="selectedRequirementObjs.length >= 1">
      <b-link @click="openContextSidebar">
        <span class="text-primary font-weight-bolder">Open Details</span>
      </b-link>
    </li>

    <li v-if="selectedRequirementObjs.length === 1">
      <b-link @click="openUpdateRequirementModal">
        <span class="text-primary font-weight-bolder">Update</span>
      </b-link>
    </li>

    <hr class="my-25">

    <li v-if="selectedRequirementObjs.length === 1" class="v-context__sub">
      <b-link>
        <span class="text-success font-weight-bold">Create</span>
      </b-link>
      <ul class="v-context">
        <li>
          <b-link @click="openCreateRequirementModal('before')">
            Before
          </b-link>
        </li>
        <li>
          <b-link @click="openCreateRequirementModal('after')">
            After
          </b-link>
        </li>
        <li>
          <b-link @click="openCreateRequirementModal('child')">
            As Child
          </b-link>
        </li>
      </ul>
    </li>

    <hr class="my-25">

    <!-- Requirement Priority -->
    <li class="v-context__sub">
      <b-link>
        Priority
      </b-link>
      <ul class="v-context">
        <li v-for="(item, index) in $store.state.constants.requirementPriorities" :key="index">
          <b-link @click="setPriority(item.id)">
            <span :class="allSelectedPriorities.id === item.id ? 'text-primary font-weight-bold' : ''">
              {{ item.id }}
            </span>
          </b-link>
        </li>
      </ul>
    </li>

    <!-- Requirement Security Classification -->
    <li class="v-context__sub">
      <b-link>
        Security Classification
      </b-link>
      <ul class="v-context">
        <li v-for="(item, index) in $store.state.constants.securityClassifications" :key="index">
          <b-link @click="setClassification(item.id)">
            <span :class="allSelectedSecurityClassifications.id === item.id ? 'text-primary font-weight-bold' : ''">
              {{ item.id }}
            </span>
          </b-link>
        </li>
      </ul>
    </li>

    <!-- Requirement Status -->
    <li class="v-context__sub">
      <b-link>
        Status
      </b-link>
      <ul class="v-context">
        <li v-for="(item, index) in $store.state.constants.requirementStatuses" :key="index">
          <b-link @click="setStatus(item.id)">
            <span :class="allSelectedStatuses.id === item.id ? 'text-primary font-weight-bold' : ''">
              {{ item.id }}
            </span>
          </b-link>
        </li>
      </ul>
    </li>

    <hr class="my-25">

    <li>
      <b-link class="d-flex align-items-center" @click="openLinkBackwardTraceModal">
        <feather-icon icon="ArrowLeftIcon" size="16" class="mr-25" />
        <span class="font-weight-bold">Trace Backward</span>
      </b-link>
    </li>

    <li>
      <b-link class="d-flex align-items-center" @click="openLinkForwardTraceModal">
        <feather-icon icon="ArrowRightIcon" size="16" class="mr-25" />
        <span class="font-weight-bold">Trace Forward</span>
      </b-link>
    </li>

    <li>
      <b-link class="d-flex align-items-center" @click="openLinkIssuesModal">
        <font-awesome-icon
          :icon="['fas', 'spinner']"
          class="mr-50"
        />
        <span class="font-weight-bold">Issues</span>
      </b-link>
    </li>

    <li>
      <b-link class="d-flex align-items-center" @click="openLinkTestsModal">
        <font-awesome-icon
          :icon="['fas', 'vials']"
          class="mr-50"
        />
        <span class="font-weight-bold">Test Cases</span>
      </b-link>
    </li>

    <hr class="my-25">

    <li>
      <b-link class="d-flex align-items-center" @click="openDeleteRequirementModal">
        <feather-icon icon="TrashIcon" size="16" class="mr-50 text-danger" />
        <span class="text-danger font-weight-bold">Delete</span>
        <span
          v-if="selectedRequirementObjs.length > 1"
          class="ml-25 text-danger font-weight-bolder"
        >
          ({{ selectedRequirementObjs.length }} selected)
        </span>
      </b-link>
    </li>
  </vue-context>
</template>

<script>
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import VueContext from 'vue-context'

export default {
  name: 'RequirementContextMenu',
  components: { VueContext },
  props: {
    selectedRequirementObjs: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props, context) {
    const menu = ref(null)

    const openContextSidebar = () => { context.emit('toggle-context-sidebar') }
    const allSelectedPriorities = computed(() => store.state.constants.requirementPriorities.find(priority => props.selectedRequirementObjs.every(req => req.priority === priority.id)) || '')
    const allSelectedSecurityClassifications = computed(() => store.state.constants.securityClassifications.find(classification => props.selectedRequirementObjs.every(req => req.classification === classification.id)) || '')
    const allSelectedStatuses = computed(() => store.state.constants.requirementStatuses.find(status => props.selectedRequirementObjs.every(req => req.status === status.id)) || '')
    const setClassification = classification => {
      store.dispatch('requirementsTable/bulkSetRequirementSecurityClassification', {
        reqIdList: props.selectedRequirementObjs.map(x => x.id),
        classification,
      })
    }
    const setPriority = priority => {
      store.dispatch('requirementsTable/bulkSetRequirementPriority', {
        reqIdList: props.selectedRequirementObjs.map(x => x.id),
        priority,
      })
    }
    const setStatus = status => {
      store.dispatch('requirementsTable/bulkSetRequirementStatus', {
        reqIdList: props.selectedRequirementObjs.map(x => x.id),
        status,
      })
    }

    const openCreateRequirementModal = relation => {
      // Set the target details
      const target = { targetId: props.selectedRequirementObjs[0].id, targetRelation: relation }
      store.commit('requirement/SET_TARGET', target)

      // Open create requirement modal
      context.root.$bvModal.show('create-requirement-modal')
    }
    const openUpdateRequirementModal = () => { context.root.$bvModal.show('update-requirement-modal') }
    const openDeleteRequirementModal = () => { context.root.$bvModal.show('delete-requirement-modal') }
    const openLinkBackwardTraceModal = () => { context.root.$bvModal.show('link-backward-trace-requirement-modal') }
    const openLinkForwardTraceModal = () => { context.root.$bvModal.show('link-forward-trace-requirement-modal') }
    const openLinkIssuesModal = () => { context.root.$bvModal.show('link-issues-to-requirements-modal') }
    const openLinkTestsModal = () => { context.root.$bvModal.show('link-test-cases-to-requirements-modal') }

    return {
      menu,

      // Actions
      openContextSidebar,
      allSelectedSecurityClassifications,
      setClassification,
      allSelectedPriorities,
      setPriority,
      allSelectedStatuses,
      setStatus,

      openCreateRequirementModal,
      openUpdateRequirementModal,
      openDeleteRequirementModal,
      openLinkBackwardTraceModal,
      openLinkForwardTraceModal,
      openLinkIssuesModal,
      openLinkTestsModal,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-context.scss';
</style>
