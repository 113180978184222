<template>
  <div>
    <h6 v-if="!hideTitle" class="text-info">
      Linked Interfaces
    </h6>

    <b-list-group v-if="allInterfaces && allInterfaces.length > 0" flush>
      <b-list-group-item
        v-for="(interfaceObj, index) in allInterfaces" :key="index"
        class="flex-column align-items-start cursor-pointer list-item py-75"
        @click.stop="routeToInterface(interfaceObj.id)"
        @click.middle.stop="routeToInterfaceNewTab(interfaceObj.id)"
      >
        <div class="d-inline-flex w-100 justify-content-start align-middle">
          <h6 class="text-primary ml-50">
            {{ interfaceObj.display_id }}
          </h6>
        </div>

        <div class="flex-column w-100">
          <div class="d-flex w-100 justify-content-start align-middle ml-50">
            <span class="ml-50 font-small-3">
              Name:
              <span :class="interfaceObj.name ? '' : 'text-muted'">
                {{ interfaceObj.name || 'n/a' }}
              </span>
            </span>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div v-else class="px-2">
      <span class="text-muted font-small-4">
        No linked interfaces
      </span>
    </div>
  </div>
</template>

<script>
import { useRouter } from '@core/utils/utils'

export default {
  name: 'ListInterfaces',
  props: {
    allInterfaces: {
      type: Array,
      default: () => [],
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { route, router } = useRouter()

    const routeToInterface = interfaceId => {
      router.push({
        name: 'interfaces',
        query: { focus: interfaceId },
      })
    }

    const routeToInterfaceNewTab = interfaceId => {
      const routeData = router.resolve({
        name: 'interfaces',
        params: { ...route.params },
        query: { focus: interfaceId },
      })
      window.open(routeData.href, '_blank')
    }

    return {
      routeToInterface,
      routeToInterfaceNewTab,
    }
  },
}
</script>

<style scoped lang="scss">
.list-item {
  border-left: 1px solid;
  border-right: 1px solid;
  border-radius: 1rem;
}
.list-item:not(:hover) {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>
