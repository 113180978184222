<template>
  <div>
    <h6 v-if="!hideTitle" class="text-info">
      Linked Behaviours
    </h6>

    <b-list-group v-if="allBehaviourNodes && allBehaviourNodes.length > 0" flush>
      <b-list-group-item
        v-for="(behaviourNode, index) in allBehaviourNodes" :key="index"
        class="list-item flex-column align-items-start cursor-pointer"
        @click.stop="routeToBehaviourNode(behaviourNode)"
        @click.middle.stop="routeToBehaviourNodeNewTab(behaviourNode)"
      >
        <div class="flex-column align-items-start">
          <p class="font-small-3 w-100 mb-0 d-inline-flex justify-content-between">
            Tree: {{ behaviourNode.bt.name }}
          </p>
          <p class="text-primary w-100 mb-0 d-inline-flex justify-content-between">
            {{ behaviourNode.name }}
            <!--<span class="text-muted font-small-3 text-nowrap overflow-hidden">{{ behaviourNode.id }}</span>-->
          </p>
        </div>
      </b-list-group-item>
    </b-list-group>

    <div v-else class="px-2">
      <span class="text-muted font-small-4">
        No linked behaviours
      </span>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils/utils'

export default {
  name: 'ListBehaviourNodes',
  props: {
    allBehaviourNodes: {
      type: Array,
      default: () => [],
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { route, router } = useRouter()

    const routeToBehaviourNode = behaviourNode => {
      store.dispatch('behaviours/selectBehaviourTree', behaviourNode.bt.id)
        .then(() => {
          router.push({
            name: 'joint_mbse_tree_focus',
            params: { ...route.params, behaviourTreeId: behaviourNode.bt.id },
            query: { focus: behaviourNode.id },
          })
        })
    }

    const routeToBehaviourNodeNewTab = behaviourNode => {
      store.dispatch('behaviours/selectBehaviourTree', behaviourNode.bt.id)
        .then(() => {
          const routeData = router.resolve({
            name: 'joint_mbse_tree_focus',
            params: { ...route.params, behaviourTreeId: behaviourNode.bt.id },
            query: { focus: behaviourNode.id },
          })
          window.open(routeData.href, '_blank')
        })
    }

    return {
      routeToBehaviourNode,
      routeToBehaviourNodeNewTab,
    }
  },
}
</script>

<style scoped lang="scss">
.list-item {
  border-left: 1px solid;
  border-right: 1px solid;
  border-radius: 1rem;
}
.list-item:not(:hover) {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>
