<template>
  <div v-if="userHasScrolledDown" class="btn-scroll-to-top show">
    <b-button
      v-b-tooltip.hover.left.v-info="'Scroll to top'"
      variant="info"
      class="btn-icon"
      @click="onClick"
    >
      <feather-icon icon="ArrowUpIcon" />
    </b-button>
  </div>
</template>

<script>
import {
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'

export default {
  name: 'ScrollToTopButton',
  setup() {
    onMounted(() => { window.addEventListener('scroll', handleScrollToTopVisibility) })
    onUnmounted(() => { window.removeEventListener('scroll', handleScrollToTopVisibility) })

    const scrollDownThreshold = 250
    const userHasScrolledDown = ref(false)

    const handleScrollToTopVisibility = () => {
      userHasScrolledDown.value = window.scrollY > scrollDownThreshold
    }

    const onClick = () => {
      window.scrollTo({ top: 0, behavior: 'instant' })
    }

    return {
      userHasScrolledDown,
      onClick,
    }
  },
}
</script>

<style scoped>
.btn-scroll-to-top {
  position: fixed;
  right: 30px;
  bottom: 5%;
  z-index: 999;
  transition: all .5s ease;
}
</style>
