<template>
  <b-modal
    id="export-requirements-modal"
    title="Export Specification"
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
    @ok="onSubmit"
    @show="onShow"
  >
    <div class="mb-1">
      <h6>File format</h6>
      <b-form-select v-model="fileFormat" :options="fileFormatOptions" />
    </div>

    <div v-if="['csv', 'xlsx', 'json'].includes(fileFormat)">
      <div class="w-100 d-inline-flex align-content-center justify-content-between">
        <!-- Available Columns -->
        <div v-if="!loadingLeft" class="w-100 search-and-select">
          <h6>Available Columns</h6>
          <b-form-select
            v-model="selectedLeft"
            :options="leftOptions"
            style="min-height: 35rem; border-top-left-radius: 0; border-top-right-radius: 0;"
            multiple
          />
        </div>
        <div
          v-else
          class="w-100 d-inline-flex justify-content-center"
          style="border: 1px solid rgb(59, 66, 83)"
        >
          <div class="d-flex align-items-center">
            <atom-spinner class="animate-pulse" />
          </div>
        </div>

        <!-- Add/Remove buttons -->
        <div class="d-flex flex-column align-self-center mx-50">
          <b-button
            variant="flat-danger"
            :disabled="!selectedRight.length"
            @click="onMoveToLeft"
          >
            <feather-icon icon="ArrowLeftIcon" size="24" />
          </b-button>
          <b-button
            variant="flat-success"
            class="mb-2"
            :disabled="!selectedLeft.length"
            @click="onMoveToRight"
          >
            <feather-icon icon="ArrowRightIcon" size="24" />
          </b-button>

          <b-button
            v-if="['csv', 'xlsx'].includes(fileFormat)"
            :variant="!selectedRight.length ? 'flat-secondary' : 'flat-primary'"
            :disabled="!selectedRight.length"
            @click="onMoveUp"
          >
            <feather-icon icon="ArrowUpIcon" size="24" />
          </b-button>
          <b-button
            v-if="['csv', 'xlsx'].includes(fileFormat)"
            :variant="!selectedRight.length ? 'flat-secondary' : 'flat-primary'"
            :disabled="!selectedRight.length"
            @click="onMoveDown"
          >
            <feather-icon icon="ArrowDownIcon" size="24" />
          </b-button>
        </div>

        <!-- Columns to export -->
        <div class="w-100 search-and-select">
          <h6>Columns to export</h6>
          <b-form-select
            v-model="selectedRight"
            :options="rightOptions"
            style="min-height: 35rem; border-top-left-radius: 0; border-top-right-radius: 0;"
            multiple
          />
        </div>
      </div>
    </div>

    <div v-if="fileFormat === 'docx'">
      <h6>Select a DOCX template</h6>
      <b-form-select v-model="selectedTemplate" :options="templateOptions" />
    </div>

    <div v-if="fileFormat === 'pdf'">
      <h6>Columns</h6>
      <ul>
        <li>Section</li>
        <li>Display ID</li>
        <li>Requirement</li>
        <li>Priority</li>
      </ul>
    </div>

    <template v-slot:modal-footer="{ok, cancel}">
      <div class="d-inline-flex w-100 justify-content-between">
        <div class="align-content-center">
          <!--<b-form-checkbox-->
          <!--  v-model="includeDeletedRequirements"-->
          <!--  class="custom-control-danger"-->
          <!--&gt;-->
          <!--  Include deleted requirements-->
          <!--</b-form-checkbox>-->
        </div>

        <div>
          <b-button variant="outline-secondary" class="mr-50" @click="cancel()">
            Dismiss
          </b-button>
          <b-button
            :variant="loadingExport ? 'outline-success' : 'success'"
            :disabled="loadingExport"
            @click="onSubmit"
          >
            <span v-if="loadingExport">
              <b-spinner small class="mr-25" />
              Exporting Requirements...
            </span>
            <span v-else>
              Export Requirements
            </span>
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import coreService from '@/libs/api-services/core-service'
import axiosIns from '@/libs/axios'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ExportRequirementsModal',
  components: { AtomSpinner },
  setup(props, context) {
    const { modelId, specId } = router.currentRoute.params
    const fileFormat = ref('csv')
    const fileFormatOptions = ref([
      { text: 'CSV', value: 'csv' },
      { text: 'XLSX', value: 'xlsx' },
      { text: 'JSON', value: 'json' },
      // { text: 'DOCX', value: 'docx' },
      // { text: 'Kompozition File', value: 'kompozition' },
      // { text: 'PDF', value: 'pdf' },
    ])
    const includeDeletedRequirements = ref(false)

    // Note: Column selection only for CSV and JSON file formats
    const getColumnOptions = () => {
      loadingLeft.value = true
      coreService.specificationsApi.getSpecificationColumns(specId, modelId)
        .then(response => { allColumns.value = response })
        .finally(() => { loadingLeft.value = false })
    }
    const allColumns = ref([])
    const loadingLeft = ref(false)
    const leftOptions = computed(() => allColumns.value.filter(column => !rightOptions.value.includes(column)).sort())
    const rightOptions = ref(['id', 'display_id', 'priority', 'object_text'])
    const onMoveToRight = () => { rightOptions.value = [...rightOptions.value, ...selectedLeft.value] }
    const onMoveToLeft = () => { rightOptions.value = rightOptions.value.filter(c => !selectedRight.value.includes(c)) }
    const onMoveUp = () => {
      // For each selected object, find its index and swap it with the previous element
      selectedRight.value.forEach(selected => {
        const index = rightOptions.value.findIndex(item => item === selected)
        if (index) {
          // Swap with the previous element
          [rightOptions.value[index - 1], rightOptions.value[index]] = [rightOptions.value[index], rightOptions.value[index - 1]]
        }
      })
      rightOptions.value = [...rightOptions.value]
    }
    const onMoveDown = () => {
      // Iterate over selected objects in reverse order to avoid conflicts during swapping
      for (let i = selectedRight.value.length - 1; i >= 0; i--) {
        const selected = selectedRight.value[i]
        const index = rightOptions.value.findIndex(item => item === selected)
        if (index < rightOptions.value.length - 1) { // Ensure the item does not move past the last element
          // Swap with the next element
          [rightOptions.value[index + 1], rightOptions.value[index]] = [rightOptions.value[index], rightOptions.value[index + 1]]
        }
      }
      rightOptions.value = [...rightOptions.value]
    }

    // Selection
    const selectedLeft = ref([])
    const selectedRight = ref([])

    // DOCX
    const templateOptions = ref([])
    const selectedTemplate = ref(null)
    const getDocxTemplateOptions = () => {
      axiosIns.get('/api/v2/requirements/docx_templates')
        .then(({ data }) => {
          templateOptions.value = data
          if (data.length) { [selectedTemplate.value] = data }
        })
    }

    const onShow = () => {
      rightOptions.value = store.state.requirementsTable.columns.map(c => c.value)
      getColumnOptions()
      getDocxTemplateOptions()
    }

    const loadingExport = ref(false)
    const onSubmit = async () => {
      loadingExport.value = true
      if (['csv', 'xlsx', 'json'].includes(fileFormat.value)) {
        await downloadCsvXlsxOrJsonFile()
      } else if (fileFormat.value === 'docx') {
        downloadDocxFile()
      } else {
        console.error('invalid file type')
      }
      loadingExport.value = false
      context.root.$bvModal.hide('export-requirements-modal')
    }

    const downloadCsvXlsxOrJsonFile = async () => {
      try {
        const response = await coreService.post(
          `/v1/specifications/${specId}/export`,
          rightOptions.value,
          {
            headers: { 'model-id': modelId },
            params: { file_format: fileFormat.value },
            responseType: 'blob',
          },
        )

        const fileName = `specification-${specId}-export.${fileFormat.value}`

        // Create the URL, then an 'a' element in the DOM and then click it to trigger an automatic file download
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        link.click()
        link.remove()
        context.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Specification exported',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        console.error(`Error downloading ${fileFormat.value.toUpperCase()}: ${error}`)
        context.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to export Specification',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    }

    const downloadDocxFile = () => {
      console.log('downloadDocxFile')
    }

    return {
      // Columns
      loadingLeft,
      leftOptions,
      rightOptions,
      selectedLeft,
      selectedRight,
      onMoveToRight,
      onMoveToLeft,
      onMoveUp,
      onMoveDown,

      fileFormat,
      fileFormatOptions,
      includeDeletedRequirements,
      selectedTemplate,
      templateOptions,
      loadingExport,
      onShow,
      onSubmit,
    }
  },
}
</script>
