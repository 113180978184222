<template>
  <b-modal
    id="link-interfaces-to-requirements-modal"
    title="Link Interfaces with Requirement(s)"
    size="lg"
    ok-only
    ok-title="Done"
    @show="onShow"
  >
    <div class="d-flex m-25">
      <!-- Available Interfaces -->
      <b-form-select
        v-model="selectedAllInterfaces"
        :options="allInterfaces"
        :disabled="loadingInterfaces"
        class="h-100 w-50"
        style="min-height: 35rem; border-top-left-radius: 0; border-top-right-radius: 0;"
        multiple
      />

      <!-- Link button -->
      <div class="d-flex flex-column align-self-center mx-50">
        <b-button
          variant="flat-success"
          :disabled="!selectedAllInterfaces.length"
          @click="linkInterface"
        >
          <feather-icon icon="ArrowRightIcon" size="24" />
        </b-button>
      </div>

      <!-- Linked Interfaces -->
      <!--<pre>{{ linkedInterfaces }}</pre>-->
      <div class="w-50">
        <h6>
          <b-badge variant="info" class="mr-50">
            {{ linkedInterfaces.length }}
          </b-badge>
          <span>Linked Interface{{ linkedInterfaces | handlePluralSuffix }}</span>
        </h6>
        <vue-perfect-scrollbar
          v-if="linkedInterfaces.length > 0"
          class="scroll-area"
          :settings="{
          maxScrollbarLength: 60,
          wheelPropagation: false,
        }"
        >
          <b-list-group>
            <b-list-group-item
              v-for="(interfaceObj, index) in linkedInterfaces"
              :key="index"
            >
              <div class="d-flex flex-column">
                <div class="d-flex justify-content-between pb-0">
                  <div class="pt-25 pl-25 mb-0 pb-0">
                    <div class="d-inline-flex w-100 justify-content-start align-middle">
                      <h6 class="text-primary ml-50">
                        {{ interfaceObj.display_id }}
                      </h6>
                    </div>

                    <div class="flex-column w-100">
                      <div class="d-flex w-100 justify-content-start align-middle ml-50">
                        <span class="ml-50 font-small-3">
                          Name:
                          <span :class="interfaceObj.name ? '' : 'text-muted'">
                            {{ interfaceObj.name || 'n/a' }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="w-50 d-inline-flex flex-row-reverse">
                    <b-button
                      variant="flat-danger"
                      size="sm"
                      class="p-25 ml-3"
                      @click="unlinkInterface(interfaceObj.id)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </vue-perfect-scrollbar>
        <div v-else>
          <p class="mt-1 ml-1 text-muted">
            No linked Interfaces...
          </p>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  computed,
  ref,
} from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'LinkInterfaces',
  components: { VuePerfectScrollbar },
  props: {
    requirementIds: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, context) {
    const { modelId } = router.currentRoute.params
    const loadingInterfaces = ref(false)
    const loadingRequirementInterfaces = ref(false)

    const allInterfaces = computed(() => {
      // Get all interfaces from the store, minus any already assigned to 'linkedInterfaces'
      // and reformat the object to be compatible with b-form-select.
      const linkedInterfaceIds = linkedInterfaces.value.map(obj => obj.id)
      return store.state.interfaces.interfaces
        .filter(obj => !linkedInterfaceIds.includes(obj.id))
        .map(obj => ({
          value: obj.id,
          text: obj.name,
        }))
    })
    const linkedInterfaces = ref([])
    const selectedAllInterfaces = ref([])
    const selectedLinkedInterfaces = ref([])

    const onShow = () => {
      loadingInterfaces.value = true
      loadingRequirementInterfaces.value = true
      store.dispatch('interfaces/fetchInterfaces').finally(() => {
        loadingInterfaces.value = false
      })
      coreService.requirementsApi.getMutualInterfacesForRequirements(modelId, props.requirementIds)
        .then(response => {
          linkedInterfaces.value = response
        })
        .finally(() => {
          loadingRequirementInterfaces.value = false
        })
    }

    const linkInterface = () => {
      coreService.requirementsApi.createInterfaceLinks(
        modelId,
        {
          req_ids: props.requirementIds,
          interface_ids: selectedAllInterfaces.value,
        },
      )
        .then(response => {
          // Add the Interface to the right pane
          selectedAllInterfaces.value.forEach(id => {
            const i = store.state.interfaces.interfaces.find(obj => obj.id === id)
            if (linkedInterfaces.value.filter(obj => obj.id === id).length === 0) {
              linkedInterfaces.value.push(i)
            }
          })

          // Show confirmation toast
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Interfaces linked',
              icon: 'CheckIcon',
              text: `
                Linked ${response.count}
                Interface${response.count === 1 ? '' : 's'}
                to
                ${props.requirementIds.length} Requirement${props.requirementIds.length === 1 ? '' : 's'}
              `,
              variant: 'success',
            },
          })
        })
    }

    const unlinkInterface = interfaceId => {
      coreService.requirementsApi.removeInterfaceLinks(modelId, {
        req_ids: props.requirementIds,
        interface_ids: [interfaceId],
      })
        .then(response => {
          // Remove the Interface from the right pane
          linkedInterfaces.value = linkedInterfaces.value.filter(obj => obj.id !== interfaceId)

          // Show confirmation toast
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Interface unlinked',
              icon: 'CheckIcon',
              text: `
                Unlinked 1 Interface from
                ${props.requirementIds.length} Requirement${props.requirementIds.length === 1 ? '' : 's'}
              `,
              variant: 'success',
            },
          })
        })
    }

    return {
      loadingInterfaces,
      loadingRequirementInterfaces,
      selectedAllInterfaces,
      selectedLinkedInterfaces,
      allInterfaces,
      linkedInterfaces,
      onShow,
      linkInterface,
      unlinkInterface,
    }
  },
}
</script>

<style lang="scss">
// Set the modal width manually because the default Bootstrap ones aren't sufficient
#link-interfaces-to-requirements-modal .modal-dialog {
  max-width: 60vw;
}
</style>
