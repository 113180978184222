<template>
  <div>
    <!-- Backward Trace -->
    <list-trace :all-trace-items="traceItems" alternative-headers />

    <hr class="my-2">

    <!-- Forward Trace -->
    <list-trace is-forward-trace :all-trace-items="coverageItems" alternative-headers />
  </div>
</template>

<script>
import {
  computed,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ListTrace from './ListTrace.vue'

export default {
  name: 'SidebarTabTrace',
  directives: { Ripple },
  components: {
    ListTrace,
  },
  props: {
    reqId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)

    onMounted(() => { loadTraceAndCoverage() })
    watch(props, val => { loadTraceAndCoverage() })

    const loadingTrace = ref(false)
    const loadingCoverage = ref(false)
    const loadTraceAndCoverage = () => {
      loadTrace()
      loadCoverage()
    }

    const localReqObj = computed(() => props.reqObj)
    const traceItems = ref([])
    const coverageItems = ref([])

    // Load Backward Trace (also known as Trace)
    const loadTrace = () => {
      loadingTrace.value = true
      store.dispatch('requirement/getRequirementTrace', props.reqId)
        .then(response => { traceItems.value = response })
        .finally(() => { loadingTrace.value = false })
    }

    // Load Forward Trace (also known as Coverage)
    const loadCoverage = () => {
      loadingCoverage.value = true
      store.dispatch('requirement/getRequirementCoverage', props.reqId)
        .then(data => { coverageItems.value = data })
        .finally(() => { loadingCoverage.value = false })
    }

    return {
      localReqObj,
      traceItems,
      coverageItems,
      loadingTrace,
      loadingCoverage,
    }
  },
}
</script>
