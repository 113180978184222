<template>
  <div>
    <v-select
      v-model="selectedSpecification"
      :disabled="loadingSpecifications"
      :loading="loadingSpecifications"
      label="title"
      style="min-width: 30vw;"
      show-all
      :clearable="false"
      :options="allSpecifications"
      @input="changeSpecification"
    >
      <template #spinner="{ loadingRequirements }">
        <div
          v-if="loadingRequirements"
          style="border-left-color: rgba(88, 151, 251, 0.71)"
          class="vs__spinner"
        />
      </template>
    </v-select>
  </div>
</template>

<script>
import store from '@/store'
import {
  computed,
  ref,
  onUnmounted,
  watch,
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  name: 'SpecificationSelector',
  directives: { Ripple },
  components: { vSelect },
  setup(props, context) {
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)

    // Watch for when any route parameters change:
    watch(routeParams, () => {
      // Update the selected Specification if it has changed within the route
      selectedSpecification.value = store.state.specification.list.find(spec => spec.id === routeParams.value.specId)
    })
    onUnmounted(() => { store.commit('app/CLEAR_DYNAMIC_PAGE_TITLE') })

    // Specification selection logic
    const selectedSpecification = ref(null)
    const allSpecifications = computed(() => store.state.specification.list)
    const loadingSpecifications = ref(false)
    const fetchSpecificationsList = () => {
      loadingSpecifications.value = true
      store
        .dispatch('specification/getAllSpecifications', { modelId: routeParams.value.modelId })
        .then(() => {
          selectedSpecification.value = store.state.specification.list.find(spec => spec.id === routeParams.value.specId)
        })
        .finally(() => { loadingSpecifications.value = false })
    }
    fetchSpecificationsList()

    const changeSpecification = specObj => {
      if (specObj) {
        router.push({
          name: 'requirements_table',
          params: { ...route.params, specId: specObj.id },
        })
      } else {
        console.error(`Invalid specId value: ${specObj}`)
      }
    }
    watch(selectedSpecification, () => {
      // Change the page title everytime the selected specification changes or is loaded
      store.commit('app/SET_DYNAMIC_PAGE_TITLE', selectedSpecification.value.title)
    })

    return {
      loadingSpecifications,
      allSpecifications,
      selectedSpecification,
      changeSpecification,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
