<template>
<div>
  <div v-if="localReqObj" class="h-100" :class="loading ? 'blur-bg' : ''">
    <LabelWithHoverIcon
      label-string="Object Text"
      popover-text="Requirement object text refers to the textual description outlining the details of a specific requirement."
      class="mb-25"
    />
    <tip-tap-editor
      v-model="localReqObj.object_text"
      :disabled="loading"
      placeholder="Edit Requirement text..."
      class="mb-50 clearfix"
      min-height="10"
      max-height="10"
    />

    <hr class="my-2 px-50">

    <h5 class="mt-2 mb-1">
      Details
    </h5>

    <!-- Requirement Priority -->
    <p class="flex-column w-100 px-50">
      <LabelWithHoverIcon
        label-string="Priority"
        popover-text="Requirement Priority denotes the level of importance assigned to requirements based on their impact on system functionality, performance, or stakeholder needs."
        class="mb-25"
      />
      <b-form-select
        v-model="localReqObj.priority"
        :disabled="loading"
        :options="$store.getters['constants/requirementPriorities']"
      />
    </p>

    <!-- Security Classification -->
    <p class="flex-column w-100 px-50">
      <LabelWithHoverIcon
        label-string="Security Classification"
        popover-text="Security classification level indicates the degree of sensitivity or confidentiality assigned to information, determining the level of protection and access controls required to safeguard it from unauthorized disclosure or compromise. Note: This property has NO effect on user access to this object."
        class="mb-25"
      />
      <b-form-select
        v-model="localReqObj.classification"
        :disabled="loading"
        :options="$store.getters['constants/securityClassifications']"
      />
    </p>

    <!-- Requirement Status -->
    <p class="flex-column w-100 px-50">
      <LabelWithHoverIcon
        label-string="Status"
        popover-text="Requirement status represents the current state or progress of a requirement within a project's lifecycle, indicating whether it's open, in review, reviewed, or approved/baselined."
        class="mb-25"
      />
      <b-form-select
        v-model="localReqObj.status"
        :disabled="loading"
        :options="$store.getters['constants/requirementStatuses']"
      />
    </p>

    <hr class="my-2 px-50">

    <h5 class="mt-2 mb-1">
      Additional Attributes
    </h5>

    <!-- Additional Attributes -->
    <div v-for="(value, key) in localReqObj.additional_attributes" :key="key" class="flex-column w-100 px-50 mb-1">
      <LabelWithHoverIcon :label-string="key | capitalizeUnderscoredEachWord" class="mb-25" />
      <div class="d-inline-flex w-100">
        <b-form-input v-model="localReqObj.additional_attributes[key]" placeholder="(empty)" :disabled="loading" />
        <b-button
          class="ml-50"
          variant="flat-danger"
          @click="removeAttribute(key)"
        >
          <feather-icon icon="Trash2Icon" />
        </b-button>
      </div>
    </div>

    <div class="flex-column w-100 px-50 mt-2">
      <LabelWithHoverIcon label-string="New Attribute" class="mb-25" label-class="text-success" />
      <div class="d-inline-flex w-100">
        <b-form-input v-model="newAttributeKey" :disabled="loading" placeholder="Attribute Name" />
        <b-button
          size="sm"
          class="mx-50 text-nowrap"
          variant="flat-success"
          :disabled="loading || newAttributeKey.length === 0"
          @click="addAttribute"
        >
          <feather-icon icon="PlusIcon" />
          Add New
        </b-button>
      </div>

      <p class="font-small-3 px-50 mt-1 mb-0 text-muted">
        <span class="font-weight-bolder">Note:</span>
        Empty attribute values are not saved to, and also removed from the Requirement. Attributes shown are derived from additional
        attributes on this Requirement and additional attributes used on other Requirements in the same Specification.
      </p>
    </div>

    <div class="d-inline-flex w-100 justify-content-end px-50 mt-2">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        size="sm"
        variant="outline-danger"
        class="mr-50"
        @click="onReset"
      >
        Reset
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        size="sm"
        variant="success"
        class="mr-50"
        @click="onSave"
      >
        Save changes
      </b-button>
    </div>

    <hr class="my-2 px-50">

    <!-- Created -->
    <div class="flex-column w-100 px-50">
      <p v-b-tooltip.hover.top="localReqObj.created" class="font-small-4">
        Created
        <span class="text-primary mx-25">{{ localReqObj.created | diffForHumansMaxTwoWeeks }}</span>
        by
        <span class="text-primary mx-25">{{ getUserUsername(localReqObj.created_by) }}</span>
      </p>
    </div>

    <!-- Updated -->
    <div v-if="localReqObj.updated" class="flex-column w-100 px-50">
      <p v-b-tooltip.hover.top="localReqObj.updated" class="font-small-3">
        Last updated
        <span class="font-weight-bold text-primary">{{ localReqObj.updated | diffForHumansMaxTwoWeeks }}</span>
        by
        <span class="font-weight-bold text-primary">{{ getUserUsername(localReqObj.updated_by) }}</span>
      </p>
    </div>

    <!-- UUID -->
    <div v-if="localReqObj" class="flex-column w-100 px-50">
      <p class="font-small-2">
        <span class="text-muted mr-25">Requirement ID:</span>
        <span class="text-muted select-all">{{ localReqObj.id }}</span>
      </p>
    </div>
  </div>
  <div
    v-if="loading"
    id="loader"
    class="position-absolute position-top-0 w-100 vh-100 d-flex justify-content-center align-items-center"
    style="z-index: 900;"
  >
    <atom-spinner class="animate-pulse" />
  </div>
</div>
</template>

<script>
import {
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'
import store from '@/store'
import Vue from 'vue'
import _ from 'lodash'
import Ripple from 'vue-ripple-directive'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import LabelWithHoverIcon from '@/components/Forms/LabelWithHoverIcon.vue'

export default {
  name: 'RequirementDetailsForm',
  directives: { Ripple },
  components: {
    TipTapEditor,
    AtomSpinner,
    LabelWithHoverIcon,
  },
  props: {
    reqId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    onMounted(() => { loadRequirementDetails() })
    watch(props, val => { loadRequirementDetails() })

    const loading = ref(false)
    const localReqObj = ref({})
    const etag = ref(null)

    const loadRequirementDetails = () => {
      loading.value = true
      localReqObj.value = null
      store
        .dispatch('requirement/getRequirementDetails', props.reqId)
        .then(response => {
          localReqObj.value = response.data
          etag.value = response.headers.etag

          // Ensure that all additional_attribute values are a String
          Object.entries(localReqObj.value.additional_attributes).forEach(([key, value]) => {
            if (typeof value !== 'string' && value !== 'number') {
              localReqObj.value.additional_attributes[key] = String(value)
            }
          })

          fetchAdditionalSpecAttributes()
        })
        .finally(() => { loading.value = false })
    }

    const onSave = () => {
      loading.value = true
      store
        .dispatch('requirement/updateRequirementDetails', {
          id: localReqObj.value.id,
          etag: etag.value,
          data: {
            priority: localReqObj.value.priority,
            classification: localReqObj.value.classification,
            object_text: localReqObj.value.object_text,
            status: localReqObj.value.status,
            spec_id: localReqObj.value.spec_id,
            additional_attributes: localReqObj.value.additional_attributes,
          },
        })
        .then(response => {
          store.commit('requirementsTable/INJECT_UPDATED_REQUIREMENT', response.data)
          loadRequirementDetails()
        })
        .finally(() => { loading.value = false })
    }
    const onReset = () => { loadRequirementDetails() }

    // Additional Attributes
    const newAttributeKey = ref('')
    const addAttribute = () => {
      // Sanitise key: Remove leading/trailing whitespace, convert spaces to '_' and convert to lowercase
      newAttributeKey.value = newAttributeKey.value.replace(/ /g, '_')
      newAttributeKey.value = newAttributeKey.value.toLowerCase()
      newAttributeKey.value = newAttributeKey.value.trim()

      Vue.set(localReqObj.value.additional_attributes, newAttributeKey.value, '')
      newAttributeKey.value = ''
    }
    const removeAttribute = key => { localReqObj.value.additional_attributes[key] = '' }
    const fetchAdditionalSpecAttributes = () => {
      store
        .dispatch('requirementsTable/getAdditionalCustomColumns')
        .then(columns => {
          const additionalSpecAttributes = columns.reduce((obj, item) => {
            obj[item.toLowerCase()] = ''
            return obj
          }, {})
          const additionalReqAttributes = localReqObj.value.additional_attributes
          localReqObj.value.additional_attributes = { ...additionalSpecAttributes, ...additionalReqAttributes }
        })
    }

    return {
      loading,
      localReqObj,
      onSave,
      onReset,

      newAttributeKey,
      addAttribute,
      removeAttribute,
    }
  },
}
</script>

<style scoped lang="scss">
.blur-bg {
  filter: blur(3px) sepia(50%);
}
</style>
