<template>
  <div>
    <div class="h-100" :class="loading ? 'blur-bg' : ''">
      <!-- Create new note -->
      <div class="list-item d-inline-flex w-100">
        <div class="mr-50 mt-25">
          <b-avatar variant="info" size="sm">
            <!-- TODO Resolve User avatar -->
          </b-avatar>
        </div>

        <div v-if="isEditing" class="w-100 flex-column">
          <tip-tap-editor
            ref="tipTapNewNote"
            v-model="localNote.text"
            placeholder="Add a note..."
            :allow-image-upload="false"
            min-height="6"
            max-height="10"
          />

          <div class="mt-50">
            <b-button variant="success" size="sm" class="mr-25" @click="createNote">
              {{ savingNewNote ? 'Saving' : 'Save' }}
            </b-button>
            <b-button variant="flat-secondary" size="sm" @click="() => { localNote.text = ''; isEditing = false }">
              Cancel
            </b-button>
          </div>
        </div>
        <div v-else class="w-100 flex-column">
          <b-form-input placeholder="Add a note..." @click="startEditing" />
        </div>
      </div>

      <list-notes
        v-if="notes.length > 0"
        title="Notes"
        :all-notes="notes"
        class="mt-2"
      />
    </div>
    <div
      v-if="loading"
      id="loader"
      class="position-absolute position-top-0 w-100 vh-100 d-flex justify-content-center align-items-center"
      style="z-index: 900;"
    >
      <atom-spinner class="animate-pulse" />
    </div>
  </div>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import { useRouter } from '@core/utils/utils'
import {
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import ListNotes from '@/components/Notes/ListNotes.vue'

export default {
  name: 'SidebarTabNotes',
  components: {
    TipTapEditor,
    AtomSpinner,
    ListNotes,
  },
  props: {
    reqId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const { router } = useRouter()
    const { modelId } = router.currentRoute.params

    onMounted(() => { loadNotes() })
    watch(props, val => { loadNotes() })

    const loading = ref(false)
    const notes = ref([])
    const tipTapNewNote = ref(null)

    const isEditing = ref(false)
    const localNote = ref({ text: '', type: 'note' })

    const loadNotes = () => {
      loading.value = true
      coreService.noteApi.listNotesForNode(props.reqId, modelId)
        .then(response => { notes.value = response })
        .finally(() => { loading.value = false })
    }

    const savingNewNote = ref(false)
    const createNote = () => {
      if (localNote.value.text.replaceAll(' ', '') === '') { return }

      savingNewNote.value = true
      coreService.noteApi.createNote(modelId, {
        text: localNote.value.text,
        type: localNote.value.type,
        parent_nodes: [props.reqId],
      })
        .then(response => {
          notes.value.unshift(response)
          localNote.value.text = ''
        })
        .finally(() => { savingNewNote.value = false })
    }

    const startEditing = () => {
      isEditing.value = true
      console.log(tipTapNewNote.value)
    }

    return {
      loading,
      notes,

      isEditing,
      tipTapNewNote,
      localNote,
      startEditing,
      createNote,
      savingNewNote,
    }
  },
}
</script>

<style scoped lang="scss">
.blur-bg {
  filter: blur(3px) sepia(50%);
}
</style>
