<template>
<b-modal
  id="link-issues-to-requirements-modal"
  title="Link Issues with Requirement(s)"
  size="xl"
  ok-only
  ok-title="Done"
  @show="onShow"
>
  <div class="d-flex m-25">
    <!-- Available Issues -->
    <b-form-select
      v-model="selectedAllIssues"
      :options="allIssues"
      :disabled="loadingIssues"
      class="h-100"
      style="min-height: 35rem; border-top-left-radius: 0; border-top-right-radius: 0;"
      multiple
    />

    <!-- Link button -->
    <div class="d-flex flex-column align-self-center mx-50">
      <b-button
        variant="flat-success"
        :disabled="!selectedAllIssues.length"
        @click="linkIssue"
      >
        <feather-icon icon="ArrowRightIcon" size="24" />
      </b-button>
    </div>

    <!-- Linked Issues -->
    <!--<pre>{{ mutualIssues }}</pre>-->
    <div class="w-50">
      <h6 class="d-inline-flex justify-content-between w-100">
        <span>
          <b-badge variant="info" class="mr-50">
            {{ linkedIssues.length }}
          </b-badge>
          <span>Linked Issue{{ linkedIssues | handlePluralSuffix }}</span>
        </span>
        <b-link class="mr-2" @click.stop="issueModalRaise('multiple', 'Requirement', raiseIssue)">
          <feather-icon icon="PlusIcon" class="text-success" />
        </b-link>
      </h6>
      <vue-perfect-scrollbar
        v-if="linkedIssues.length > 0"
        class="scroll-area"
        :settings="{
          maxScrollbarLength: 60,
          wheelPropagation: false,
        }"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(issue, index) in linkedIssues"
            :key="index"
          >
            <div class="d-flex flex-column">
              <div class="d-flex justify-content-between pb-0">
                <div class="pt-25 pl-25 mb-0 pb-0">
                  <abbr
                    v-b-popover.hover.bottom.html="`${issue.description}`"
                    :title="issue.display_id"
                    class="mr-1 text-nowrap font-weight-bolder text-primary"
                  >
                    {{ issue.display_id }}
                  </abbr>
                  <p class="mb-0 mt-25" v-sanitized-html="issue.name" />
                </div>
                <div class="w-50 d-inline-flex flex-row-reverse">
                  <b-button
                    variant="flat-danger"
                    size="sm"
                    class="p-25 ml-3"
                    @click="unlinkIssue(issue.id)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </vue-perfect-scrollbar>
      <div v-else>
        <p class="mt-1 ml-1 text-muted">
          No linked Issues...
        </p>
      </div>
    </div>
  </div>
</b-modal>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed, ref } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'LinkIssues',
  components: { VuePerfectScrollbar },
  props: {
    requirementIds: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, context) {
    const { modelId } = router.currentRoute.params
    const loadingIssues = ref(false)
    const loadingRequirementIssues = ref(false)

    const allIssues = computed(() => {
      // Get all issues from the store, minus any already assigned to 'linkedIssues'
      // and reformat the object to be compatible with b-form-select.
      const linkedIssueIds = linkedIssues.value.map(issue => issue.id)
      return store.state.issues.issues
        .filter(issue => !linkedIssueIds.includes(issue.id))
        .map(issue => ({
          value: issue.id,
          text: `${issue.display_id}: ${issue.name}`,
        }))
    })
    const linkedIssues = ref([])
    const selectedAllIssues = ref([])
    const selectedLinkedIssues = ref([])

    const onShow = async () => {
      loadingIssues.value = true
      loadingRequirementIssues.value = true
      store.dispatch('issues/getIssues').finally(() => { loadingIssues.value = false })
      coreService.requirementsApi.getMutualIssuesForRequirements(modelId, props.requirementIds)
        .then(response => { linkedIssues.value = response })
        .finally(() => { loadingRequirementIssues.value = false })
    }

    const raiseIssue = issue => {
      onShow().then(() => {
        selectedAllIssues.value = [issue.id]
        linkIssue()
      })
    }

    const linkIssue = () => {
      coreService.requirementsApi.createIssueLinks(
        modelId,
        {
          req_ids: props.requirementIds,
          issue_ids: selectedAllIssues.value,
        },
      )
        .then(response => {
          // Add the Issue to the right pane
          selectedAllIssues.value.forEach(issueId => {
            const issue = store.state.issues.issues.find(storeIssue => storeIssue.id === issueId)
            if (linkedIssues.value.filter(mI => mI.id === issueId).length === 0) {
              linkedIssues.value.push(issue)
            }
          })

          // Show confirmation toast
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Issues linked',
              icon: 'CheckIcon',
              text: `
                Linked ${response.count}
                Issue${response.count === 1 ? '' : 's'}
                to
                ${props.requirementIds.length} Requirement${props.requirementIds.length === 1 ? '' : 's'}
              `,
              variant: 'success',
            },
          })
        })
    }

    const unlinkIssue = issueId => {
      coreService.requirementsApi.removeIssueLinks(modelId, {
        req_ids: props.requirementIds,
        issue_ids: [issueId],
      })
        .then(response => {
          // Remove the Issue from the right pane
          linkedIssues.value = linkedIssues.value.filter(issue => issue.id !== issueId)

          // Show confirmation toast
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Issue unlinked',
              icon: 'CheckIcon',
              text: `
                Unlinked 1 Issue from
                ${props.requirementIds.length} Requirement${props.requirementIds.length === 1 ? '' : 's'}
              `,
              variant: 'success',
            },
          })
        })
    }

    return {
      loadingIssues,
      loadingRequirementIssues,
      selectedAllIssues,
      selectedLinkedIssues,
      allIssues,
      linkedIssues,
      onShow,
      raiseIssue,
      linkIssue,
      unlinkIssue,
    }
  },
}

</script>

<style scoped lang="scss">

</style>
