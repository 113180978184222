<template>
  <div>
    <h6 class="text-info">
      Linked Components
    </h6>

    <b-list-group v-if="allComponents && allComponents.length > 0" flush>
      <b-list-group-item
        v-for="(component, index) in allComponents" :key="index"
        class="flex-column align-items-start cursor-pointer list-item"
        @click.stop="routeToOntology(component.id)"
        @click.middle.stop="routeToOntologyNewTab(component.id)"
      >
        <div class="d-flex w-100 justify-content-start">
          <span class="mb-0 font-weight-bold">
            <span class="text-primary">{{ component.qualified_name }}</span>
          </span>
          <span class="px-50 font-weight-bolder text-muted">
            [ {{ component.rel.allocation_type ? component.rel.allocation_type : '-' }} ]
          </span>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div v-else class="px-2">
      <span class="text-muted font-small-4">
        No allocated components
      </span>
    </div>
  </div>
</template>

<script>
import { useRouter } from '@core/utils/utils'

export default {
  name: 'ListComponents',
  props: {
    allComponents: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const { route, router } = useRouter()

    const routeToOntology = componentId => {
      router.push({
        name: 'domain_ontology_focus',
        params: { ...route.params, focus: componentId },
      })
    }

    const routeToOntologyNewTab = componentId => {
      const routeData = router.resolve({
        name: 'domain_ontology_focus',
        params: { ...route.params, focus: componentId },
      })
      window.open(routeData.href, '_blank')
    }

    return {
      routeToOntology,
      routeToOntologyNewTab,
    }
  },
}
</script>

<style scoped lang="scss">
.list-item {
  border-left: 1px solid;
  border-right: 1px solid;
  border-radius: 1rem;
}
.list-item:not(:hover) {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>
