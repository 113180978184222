<template>
<b-modal
  id="link-test-cases-to-requirements-modal"
  title="Link Test Cases with Requirement(s)"
  size="xl"
  ok-only
  ok-title="Done"
  @show="onShow"
>
  <div class="d-flex m-25">
    <!-- Available Test Cases -->
    <b-form-select
      v-model="selectedAllTests"
      :options="allTests"
      :disabled="loadingTests"
      class="h-100"
      style="min-height: 35rem; border-top-left-radius: 0; border-top-right-radius: 0;"
      multiple
    />

    <!-- Link button -->
    <div class="d-flex flex-column align-self-center mx-50">
      <b-button
        variant="flat-success"
        :disabled="!selectedAllTests.length"
        @click="linkTest"
      >
        <feather-icon icon="ArrowRightIcon" size="24" />
      </b-button>
    </div>

    <!-- Linked Test Cases -->
    <!--<pre>{{ linkedTests }}</pre>-->
    <div class="w-50">
      <h6>
        <b-badge variant="info" class="mr-50">
          {{ linkedTests.length }}
        </b-badge>
        <span>Linked Test Case{{ linkedTests | handlePluralSuffix }}</span>
      </h6>
      <vue-perfect-scrollbar
        v-if="linkedTests.length > 0"
        class="scroll-area"
        :settings="{
          maxScrollbarLength: 60,
          wheelPropagation: false,
        }"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(test, index) in linkedTests"
            :key="index"
          >
            <div class="d-flex flex-column">
              <div class="d-flex justify-content-between pb-0">
                <div class="pt-25 pl-25 mb-0 pb-0">
                  <abbr
                    v-b-popover.hover.bottom.html="`${test.name}`"
                    :title="test.ref_id"
                    class="mr-1 text-nowrap font-weight-bolder text-primary"
                  >
                    {{ test.ref_id }}
                  </abbr>
                  <pre class="mb-0 mt-25">{{ test.name }}</pre>
                </div>
                <div class="w-50 d-inline-flex flex-row-reverse">
                  <b-button
                    variant="flat-danger"
                    size="sm"
                    class="p-25 ml-3"
                    @click="unlinkTest(test.id)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </vue-perfect-scrollbar>
      <div v-else>
        <p class="mt-1 ml-1 text-muted">
          No linked Tests...
        </p>
      </div>
    </div>
  </div>
</b-modal>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed, ref } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'LinkTestCases',
  components: { VuePerfectScrollbar },
  props: {
    requirementIds: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, context) {
    const { modelId } = router.currentRoute.params
    const loadingTests = ref(false)
    const loadingRequirementTests = ref(false)

    const allTests = computed(() => {
      // Get all tests from the store, minus any already assigned to 'linkedTests'
      // and reformat the object to be compatible with b-form-select.
      const linkedTestIds = linkedTests.value.map(test => test.id)
      return store.state.tests.tests
        .filter(test => !linkedTestIds.includes(test.id))
        .map(test => ({
          value: test.id,
          text: `${test.ref_id}: ${test.name}`,
        }))
    })
    const linkedTests = ref([])
    const selectedAllTests = ref([])
    const selectedLinkedTests = ref([])

    const onShow = () => {
      loadingTests.value = true
      loadingRequirementTests.value = true
      store.dispatch('tests/getTests').finally(() => { loadingTests.value = false })
      coreService.requirementsApi.getMutualTestsForRequirements(modelId, props.requirementIds)
        .then(response => { linkedTests.value = response })
        .finally(() => { loadingRequirementTests.value = false })
    }

    const linkTest = () => {
      coreService.requirementsApi.createTestLinks(
        modelId,
        {
          req_ids: props.requirementIds,
          test_ids: selectedAllTests.value,
        },
      )
        .then(response => {
          // Add the Test to the right pane
          selectedAllTests.value.forEach(testId => {
            const test = store.state.tests.tests.find(storeTest => storeTest.id === testId)
            if (linkedTests.value.filter(mT => mT.id === testId).length === 0) {
              linkedTests.value.push(test)
            }
          })

          // Show confirmation toast
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Tests linked',
              icon: 'CheckIcon',
              text: `
                Linked ${response.count}
                Test Case${response.count === 1 ? '' : 's'}
                to
                ${props.requirementIds.length} Requirement${props.requirementIds.length === 1 ? '' : 's'}
              `,
              variant: 'success',
            },
          })
        })
    }

    const unlinkTest = testId => {
      coreService.requirementsApi.removeTestLinks(modelId, {
        req_ids: props.requirementIds,
        test_ids: [testId],
      })
        .then(response => {
          // Remove the Test from the right pane
          linkedTests.value = linkedTests.value.filter(test => test.id !== testId)

          // Show confirmation toast
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Test unlinked',
              icon: 'CheckIcon',
              text: `
                Unlinked 1 Test Case from
                ${props.requirementIds.length} Requirement${props.requirementIds.length === 1 ? '' : 's'}
              `,
              variant: 'success',
            },
          })
        })
    }

    return {
      loadingTests,
      loadingRequirementTests,
      selectedAllTests,
      selectedLinkedTests,
      allTests,
      linkedTests,
      onShow,
      linkTest,
      unlinkTest,
    }
  },
}

</script>

<style scoped lang="scss">

</style>
