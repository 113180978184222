<template>
  <b-modal
    id="show-hide-columns-modal"
    title="Show / Hide Columns"
    size="lg"
    @shown="onShown"
    @close="clearForms"
    @hide="clearForms"
  >
    <div class="w-100 d-inline-flex align-content-center justify-content-between">
      <!-- Available Columns -->
      <div v-if="!loadingLeft" class="w-100 search-and-select">
        <!--<b-form-input-->
        <!--  v-model="searchLeft"-->
        <!--  placeholder="Search available columns..."-->
        <!--  style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;"-->
        <!--/>-->
        <b-form-select
          v-model="selectedLeft"
          :options="leftOptions"
          style="min-height: 35rem; border-top-left-radius: 0; border-top-right-radius: 0;"
          multiple
        />
      </div>
      <div
        v-else
        class="w-100 d-inline-flex justify-content-center"
        style="border: 1px solid rgb(59, 66, 83)"
      >
        <div class="d-flex align-items-center">
          <atom-spinner class="animate-pulse" />
        </div>
      </div>

      <!-- Add/Remove buttons -->
      <div class="d-flex flex-column align-self-center mx-50">
        <b-button
          variant="flat-danger"
          :disabled="!selectedRight.length"
          @click="onMoveToLeft"
        >
          <feather-icon icon="ArrowLeftIcon" size="24" />
        </b-button>
        <b-button
          variant="flat-success"
          class="mb-2"
          :disabled="!selectedLeft.length"
          @click="onMoveToRight"
        >
          <feather-icon icon="ArrowRightIcon" size="24" />
        </b-button>

        <b-button
          :variant="!selectedRight.length ? 'flat-secondary' : 'flat-primary'"
          :disabled="!selectedRight.length"
          @click="onMoveUp"
        >
          <feather-icon icon="ArrowUpIcon" size="24" />
        </b-button>
        <b-button
          :variant="!selectedRight.length ? 'flat-secondary' : 'flat-primary'"
          :disabled="!selectedRight.length"
          @click="onMoveDown"
        >
          <feather-icon icon="ArrowDownIcon" size="24" />
        </b-button>
      </div>

      <!-- Enabled Columns -->
      <div class="w-100 search-and-select">
        <!--<b-form-input-->
        <!--  v-model="searchRight"-->
        <!--  placeholder="Search enabled columns..."-->
        <!--  style="border-bottom-left-radius: 0; border-bottom-right-radius: 0;"-->
        <!--/>-->
        <b-form-select
          v-model="selectedRight"
          :options="rightOptions"
          style="min-height: 35rem; border-top-left-radius: 0; border-top-right-radius: 0;"
          multiple
        />
      </div>
    </div>
    <p class="mt-50 ml-50">
      Section, Display Id, Priority and Classification are all mandatory columns and cannot be hidden.
    </p>

    <!-- Buttons -->
    <template v-slot:modal-footer>
      <div class="d-inline-flex w-100 justify-content-between">
        <b-button
          variant="outline-warning"
          @click="onResetToDefault"
        >
          Reset to default
        </b-button>

        <div>
          <b-button variant="outline-success" @click="onDone">
            Done
          </b-button>
        </div>
      </div>
    </template>
    <!-- ./Buttons -->
  </b-modal>
</template>

<script>
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'

export default {
  name: 'ShowHideColumnsModal',
  components: { AtomSpinner },
  setup(props, context) {
    // Managed Column Lists
    const loadingLeft = ref(false)
    const leftOptions = computed(() => {
      const rightOptionColumns = rightOptions.value.map(o => o.value)
      return store.state.requirementsTable.allColumns
        .filter(column => !rightOptionColumns.includes(column.value))
        .sort()
    })
    const rightOptions = computed(() => store.state.requirementsTable.columns)

    // Selection
    const selectedLeft = ref([])
    const selectedRight = ref([])

    // Text Search
    const searchRight = ref('')
    const searchLeft = ref('')

    const onShown = () => {
      loadingLeft.value = true
      store
        .dispatch('requirementsTable/getAllColumns')
        .finally(() => {
          loadingLeft.value = false
        })
    }
    const onResetToDefault = () => {
      store.commit('requirementsTable/RESET_COLUMNS_TO_DEFAULT')
      clearForms()
    }

    const onDone = () => {
      clearForms()
      context.emit('on-update')
      context.root.$bvModal.hide('show-hide-columns-modal')
    }

    const onMoveToLeft = () => {
      const mandatoryColumns = ['section', 'display_id', 'priority', 'classification']
      const filteredSelectedRight = selectedRight.value.filter(column => !mandatoryColumns.includes(column))
      const payload = rightOptions.value.filter(c => !filteredSelectedRight.includes(c.value))
      store.dispatch('requirementsTable/setShownColumns', payload)
    }

    const onMoveToRight = () => {
      const selectedObjects = leftOptions.value.filter(item => selectedLeft.value.includes(item.value))
      const payload = [...rightOptions.value, ...selectedObjects]
      store.dispatch('requirementsTable/setShownColumns', payload)
    }

    const onMoveUp = () => {
      const selectedObjects = rightOptions.value.filter(item => selectedRight.value.includes(item.value))

      // For each selected object, find its index and swap it with the previous element
      selectedObjects.forEach(selected => {
        const index = rightOptions.value.findIndex(item => item.value === selected.value)
        if (index > 4) {
          // Swap with the previous element
          [rightOptions.value[index - 1], rightOptions.value[index]] = [rightOptions.value[index], rightOptions.value[index - 1]]
        }
      })
      const payload = [...rightOptions.value]
      store.dispatch('requirementsTable/setShownColumns', payload)
    }

    const onMoveDown = () => {
      const selectedObjects = rightOptions.value.filter(item => selectedRight.value.includes(item.value))

      // Iterate over selected objects in reverse order to avoid conflicts during swapping
      for (let i = selectedObjects.length - 1; i >= 0; i--) {
        const selected = selectedObjects[i]
        const index = rightOptions.value.findIndex(item => item.value === selected.value)
        if (index >= 4 && index < rightOptions.value.length - 1) { // Ensure the item does not move past the last element
          // Swap with the next element
          [rightOptions.value[index + 1], rightOptions.value[index]] = [rightOptions.value[index], rightOptions.value[index + 1]]
        }
      }
      const payload = [...rightOptions.value]
      store.dispatch('requirementsTable/setShownColumns', payload)
    }

    const clearForms = () => {
      searchLeft.value = ''
      searchRight.value = ''
      selectedLeft.value = []
      selectedRight.value = []
    }

    return {
      loadingLeft,
      leftOptions,
      rightOptions,
      selectedLeft,
      selectedRight,
      onMoveToRight,
      onMoveToLeft,
      onMoveUp,
      onMoveDown,
      // searchLeft,
      // searchRight,

      onShown,
      onDone,
      onResetToDefault,
      clearForms,
    }
  },
}
</script>

<style lang="scss">
.search-and-select {
  color: red !important;
  input {
    color: red;
  }
}
</style>
