<template>
  <b-modal
    id="link-releases-to-requirements-modal"
    title="Link Releases with Requirement(s)"
    ok-only
    ok-title="Done"
    @show="onShow"
  >
    <div class="d-flex m-25">
      <!-- Available Releases -->
      <b-form-select
        v-model="selectedAllReleases"
        :options="allReleases"
        :disabled="loadingReleases"
        class="h-100 w-50"
        style="min-height: 35rem; border-top-left-radius: 0; border-top-right-radius: 0;"
        multiple
      />

      <!-- Link button -->
      <div class="d-flex flex-column align-self-center mx-50">
        <b-button
          variant="flat-success"
          :disabled="!selectedAllReleases.length"
          @click="linkRelease"
        >
          <feather-icon icon="ArrowRightIcon" size="24" />
        </b-button>
      </div>

      <!-- Linked Releases -->
      <!--<pre>{{ linkedReleases }}</pre>-->
      <div class="w-50">
        <h6>
          <b-badge variant="info" class="mr-50">
            {{ linkedReleases.length }}
          </b-badge>
          <span>Linked Release{{ linkedReleases | handlePluralSuffix }}</span>
        </h6>
        <vue-perfect-scrollbar
          v-if="linkedReleases.length > 0"
          class="scroll-area"
          :settings="{
          maxScrollbarLength: 60,
          wheelPropagation: false,
        }"
        >
          <b-list-group>
            <b-list-group-item
              v-for="(release, index) in linkedReleases"
              :key="index"
            >
              <div class="d-flex flex-column">
                <div class="d-flex justify-content-between pb-0">
                  <div class="pt-25 pl-25 mb-0 pb-0">
                    <div class="d-inline-flex w-100 justify-content-start align-middle">
                      <h6 class="text-primary ml-50">
                        {{ release.name }}
                      </h6>
                    </div>
                    <div class="flex-column w-100">
                      <div class="d-flex w-100 justify-content-start align-middle ml-2">
                        <feather-icon
                          icon="CalendarIcon"
                          size="16"
                        />
                        <span class="ml-50 font-small-3">
                        Start Date:
                        <span :class="release.start_date ? '' : 'text-muted'">
                          {{ release.start_date || 'n/a' }}
                        </span>
                      </span>
                      </div>

                      <div class="d-flex w-100 justify-content-start align-middle mt-25 ml-2">
                        <feather-icon
                          icon="CalendarIcon"
                          size="16"
                        />
                        <span class="ml-50 font-small-3">
                        Release Date:
                        <span :class="release.end_date ? '' : 'text-muted'">
                          {{ release.end_date || 'n/a' }}
                        </span>
                      </span>
                      </div>
                    </div>
                  </div>
                  <div class="w-50 d-inline-flex flex-row-reverse">
                    <b-button
                      variant="flat-danger"
                      size="sm"
                      class="p-25 ml-3"
                      @click="unlinkRelease(release.id)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </vue-perfect-scrollbar>
        <div v-else>
          <p class="mt-1 ml-1 text-muted">
            No linked Releases...
          </p>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import coreService from '@/libs/api-services/core-service'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  computed,
  ref,
} from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'LinkReleases',
  components: { VuePerfectScrollbar },
  props: {
    requirementIds: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, context) {
    const { modelId } = router.currentRoute.params
    const loadingReleases = ref(false)
    const loadingRequirementReleases = ref(false)

    const allReleases = computed(() => {
      // Get all releases from the store, minus any already assigned to 'linkedReleases'
      // and reformat the object to be compatible with b-form-select.
      const linkedReleaseIds = linkedReleases.value.map(obj => obj.id)
      return store.state.releases.releases
        .filter(obj => !linkedReleaseIds.includes(obj.id))
        .map(obj => ({
          value: obj.id,
          text: obj.name,
        }))
    })
    const linkedReleases = ref([])
    const selectedAllReleases = ref([])
    const selectedLinkedReleases = ref([])

    const onShow = () => {
      loadingReleases.value = true
      loadingRequirementReleases.value = true
      store.dispatch('releases/getReleases').finally(() => {
        loadingReleases.value = false
      })
      coreService.requirementsApi.getMutualReleasesForRequirements(modelId, props.requirementIds)
        .then(response => {
          linkedReleases.value = response
        })
        .finally(() => {
          loadingRequirementReleases.value = false
        })
    }

    const linkRelease = () => {
      coreService.requirementsApi.createReleaseLinks(
        modelId,
        {
          req_ids: props.requirementIds,
          release_ids: selectedAllReleases.value,
        },
      )
        .then(response => {
          // Add the Release to the right pane
          selectedAllReleases.value.forEach(id => {
            const release = store.state.releases.releases.find(obj => obj.id === id)
            if (linkedReleases.value.filter(obj => obj.id === id).length === 0) {
              linkedReleases.value.push(release)
            }
          })

          // Show confirmation toast
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Releases linked',
              icon: 'CheckIcon',
              text: `
                Linked ${response.count}
                Release${response.count === 1 ? '' : 's'}
                to
                ${props.requirementIds.length} Requirement${props.requirementIds.length === 1 ? '' : 's'}
              `,
              variant: 'success',
            },
          })
        })
    }

    const unlinkRelease = releaseId => {
      coreService.requirementsApi.removeReleaseLinks(modelId, {
        req_ids: props.requirementIds,
        release_ids: [releaseId],
      })
        .then(response => {
          // Remove the Release from the right pane
          linkedReleases.value = linkedReleases.value.filter(obj => obj.id !== releaseId)

          // Show confirmation toast
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Release unlinked',
              icon: 'CheckIcon',
              text: `
                Unlinked 1 Release from
                ${props.requirementIds.length} Requirement${props.requirementIds.length === 1 ? '' : 's'}
              `,
              variant: 'success',
            },
          })
        })
    }

    return {
      loadingReleases,
      loadingRequirementReleases,
      selectedAllReleases,
      selectedLinkedReleases,
      allReleases,
      linkedReleases,
      onShow,
      linkRelease,
      unlinkRelease,
    }
  },
}
</script>

<style lang="scss">
// Set the modal width manually because the default Bootstrap ones aren't sufficient
#link-releases-to-requirements-modal .modal-dialog {
  max-width: 60vw;
}
</style>
