<template>
  <div>
    <h6 v-if="!hideTitle" class="text-info">
      Linked Releases
    </h6>

    <b-list-group v-if="allReleases && allReleases.length > 0" flush>
      <b-list-group-item
        v-for="(release, index) in allReleases" :key="index"
        class="flex-column align-items-start cursor-pointer list-item py-75"
        @click.stop="routeToRelease(release.id)"
        @click.middle.stop="routeToReleaseNewTab(release.id)"
      >
        <div class="d-inline-flex w-100 justify-content-start align-middle">
          <h6 class="text-primary ml-50">
            {{ release.name }}
          </h6>
        </div>

        <div class="flex-column w-100">
          <div class="d-flex w-100 justify-content-start align-middle ml-2">
            <feather-icon
              icon="CalendarIcon"
              size="16"
            />
            <span class="ml-50 font-small-3">
              Start Date:
              <span :class="release.start_date ? '' : 'text-muted'">
                {{ release.start_date || 'n/a' }}
              </span>
            </span>
          </div>

          <div class="d-flex w-100 justify-content-start align-middle mt-25 ml-2">
            <feather-icon
              icon="CalendarIcon"
              size="16"
            />
            <span class="ml-50 font-small-3">
              Release Date:
              <span :class="release.end_date ? '' : 'text-muted'">
                {{ release.end_date || 'n/a' }}
              </span>
            </span>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
    <div v-else class="px-2">
      <span class="text-muted font-small-4">
        No linked releases
      </span>
    </div>
  </div>
</template>

<script>
import { useRouter } from '@core/utils/utils'

export default {
  name: 'ListReleases',
  props: {
    allReleases: {
      type: Array,
      default: () => [],
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { route, router } = useRouter()

    const routeToRelease = releaseId => {
      router.push({
        name: 'model_releases',
        params: { id: releaseId },
      })
    }

    const routeToReleaseNewTab = releaseId => {
      const routeData = router.resolve({
        name: 'model_releases',
        params: { ...route.params, id: releaseId },
      })
      window.open(routeData.href, '_blank')
    }

    return {
      routeToRelease,
      routeToReleaseNewTab,
    }
  },
}
</script>

<style scoped lang="scss">
.list-item {
  border-left: 1px solid;
  border-right: 1px solid;
  border-radius: 1rem;
}
.list-item:not(:hover) {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>
