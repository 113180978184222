<template>
  <b-modal
    id="delete-requirement-modal"
    title="Delete Requirement"
    size="lg"
  >
    <p>
      Are you sure you wish to
      <span class="text-danger font-weight-bolder">
        delete {{ selectedRequirementObjs.length > 1 ? 'these' : 'this' }}
        requirement{{ selectedRequirementObjs | handlePluralSuffix }} and all related children
      </span>?
    </p>

    <div class="font-small-3">
      <ul>
        <li>This will close any associated Issues that aren't linked to any other Requirements.</li>
        <li>All other associated entities will be unaffected.</li>
      </ul>
    </div>

    <div v-if="!loading">
      <hr>
      <ul v-if="selectedRequirementObjs.length > 0">
        <li v-for="(reqObj, index) in selectedRequirementObjs" :key="index">
          <span class="text-info font-weight-bold font-small-3 overflow-hidden mr-50">
            {{ reqObj.section }}
          </span>
          <span class="text-primary font-weight-bold font-small-3 mr-50">
            {{ reqObj.display_id }}
          </span>
          <span class="font-small-3 text-nowrap">
            {{ reqObj.object_text | truncate(50) }}
          </span>
        </li>
      </ul>
    </div>
    <div v-else class="d-flex flex-column align-items-center">
      <h5 class="text-warning mb-2">
        <atom-spinner class="animate-pulse" />
      </h5>
      <h5>
        Deleting Requirement{{ selectedRequirementObjs | handlePluralSuffix }}...
      </h5>
    </div>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="danger" :disabled="loading" @click="onSubmit">
        Delete {{ selectedRequirementObjs.length }} requirement{{ selectedRequirementObjs | handlePluralSuffix }} & all related children
      </b-button>
      <b-button variant="outline-secondary" :disabled="loading" @click="cancel()">
        Dismiss
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'

export default {
  name: 'DeleteRequirement',
  components: {
    AtomSpinner,
  },
  props: {
    selectedRequirementObjs: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const loading = ref(false)

    const onSubmit = () => {
      loading.value = true
      if (props.selectedRequirementObjs.length === 1) {
        store
          .dispatch('requirement/deleteRequirement', props.selectedRequirementObjs[0].id)
          .finally(() => {
            loading.value = false
            context.emit('on-submit')
            context.root.$bvModal.hide('delete-requirement-modal')
          })
      } else if (props.selectedRequirementObjs.length > 1) {
        const reqIdList = props.selectedRequirementObjs.map(r => r.id)
        store
          .dispatch('requirementsTable/bulkDeleteRequirements', reqIdList)
          .finally(() => {
            loading.value = false
            context.emit('on-submit')
            context.root.$bvModal.hide('delete-requirement-modal')
          })
      } else {
        console.warn('No requirements selected to delete.')
      }
    }

    return {
      loading,
      onSubmit,
    }
  },
}
</script>
