<template>
  <div class="btn-scroll-to-top show">
    <b-button
      id="open-context-sidebar-button"
      v-b-tooltip.hover.left.v-primary="'Show Details'"
      variant="primary"
      class="btn-icon d-flex flex-column p-50"
      @click="onClick"
    >
      <feather-icon icon="SidebarIcon" size="30" />
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'OpenContextSidebarButton',
  setup(props, context) {
    const onClick = () => { context.emit('on-click') }
    return { onClick }
  },
}
</script>

<style scoped>
.btn-scroll-to-top {
  position: fixed;
  right: 0;
  top: 20%;
  z-index: 999;
  transition: all .5s ease;
}

#open-context-sidebar-button {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: -3px 0 8px #f7991c;
}
</style>
